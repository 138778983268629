import { Sizes } from "~/utils/enums";

export default function (size: Ref<Sizes>) {
	const isSizeSmall = computed(() => size.value === "small");

	const isSizeMedium = computed(() => size.value === "medium");

	const isSizeLarge = computed(() => size.value === "large");

	const isSizeExtraLarge = computed(() => size.value === "extra-large");

	return { isSizeSmall, isSizeMedium, isSizeLarge, isSizeExtraLarge };
}
